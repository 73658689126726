<template>
  <b-modal
    id="m-Importar"
    ref="importarEmpresa"
    size="md"
    hide-footer
    no-close-on-backdrop
    :title="'Informe o dados para iniciar a ' + (isRemover ? 'remoção' : 'importação')"
    @hidden="shown = false"
  >
    <div v-if="shown">
      <b-row>
        <b-col>
          <b-form-group
            label="Anexar arquivo com empresas"
            label-for="btnUpload"
          >
            <botao-upload
              id="btnUpload"
              :ref="`uploadEmpresas`"
              v-model="form.arquivoEmpresas"
              :extensions="'.txt'"
              :upload-auto="false"
              :options="uploadOpt"
              @removed="removerArquivo()"
            /></b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!isRemover">
        <b-col>
          <certificado-select v-model="form.certificado" />
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col
          align-self="end"
          cols="auto"
        >
          <b-btn
            variant="none"
            size="sm"
            class="btn-outline-success mr-50"
            @click="importarOuDeletar"
          >{{ isRemover ? 'Remover' : 'Importar' }} empresas</b-btn>
        </b-col>
        <b-col
          align-self="end"
          cols="auto"
        >
          <b-btn
            variant="none"
            size="sm"
            class="btn-outline-danger"
            @click="close"
          >Cancelar</b-btn>
        </b-col>
      </b-row>
    </div>
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :titulo-carregamento="'Aguarde!'"
    />
  </b-modal>

</template>

<script>

import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import { updateAbility, getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import api from '../../services/api'

export default {
  components: {
    BotaoUpload: () => import('@pilar/components/botao-upload/BotaoUpload.vue'),
    CertificadoSelect: () => import('@/app/shared/components/certificado-select/CertificadoSelect.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    isRemover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      shown: false,
      uploadOpt: null,
      isLoading: false,
    }
  },
  methods: {
    importarOuDeletar() {
      if (!this.form.arquivoEmpresas) {
        this.$erro(`Não é possível ${this.isRemover ? 'deletar' : 'importar'} arquivos sem informar arquivo.`)
        return
      }

      if (this.isRemover) {
        this.removerEmpresas()
      } else {
        this.importarEmpresas()
      }
    },
    importarEmpresas() {
      this.$confirmar('Importar empresas?', 'O sistema roda uma rotina acessando o eCac e verificando quais as empresas que estão vinculadas por procuração ao certificado digital selecionado. Esse processo pode levar até 72 horas para ser concluído! O usuário pode continuar usando o sistema enquanto a tarefa é concluída.', 'warning')
        .then(payload => {
          if (payload.value) {
            this.isLoading = true
            api.importarEmpresas(this.form)
              .then(payload1 => {
                const cadastros = payload1.data
                if (typeof (cadastros) === 'string') {
                  throw new Error()
                }
                if (cadastros.length > 0) {
                  Vue.swal({
                    title: '<h4>CNPJ(s) não cadastrado(s)!</h4>',
                    html: `<div>Para maiores detalhes, tente cadastrar manualmente<br/><br/>${cadastros.join(', ')}</div>`,
                    icon: 'warning',
                    iconColor: '$warning',
                    confirmButtonText: 'OK',
                    customClass: {
                      confirmButton: 'btn btn-outline-dark mr-50',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$sucesso('Empresas cadastradas com sucesso.')
                }
                const userData = getUserData()
                api.atualizarToken(userData.email)
                  .then(payload2 => {
                    useJwt.setUserData(payload2.data.userData)
                    useJwt.setToken(payload2.data.accessToken)
                    updateAbility(this.$ability)
                    this.$emit('atualizacaoGrid')
                  })
              })
              .catch(err => {
                this.$erro(err.response.error || 'Não foi possível importar as empresas')
              })
              .finally(() => {
                this.close()
              })
          } else {
            this.close()
          }
        })
    },
    removerEmpresas() {
      this.$confirmar('Remover empresas?', 'Essa ação deletará as empresas da lista e todos os documentos vinculados a elas.', 'warning')
        .then(payload => {
          if (payload.value) {
            this.isLoading = true
            api.deletarEmpresasRange(this.form)
              .then(() => {
                this.$sucesso('Empresas deletadas com sucesso.')
                const userData = getUserData()
                api.atualizarToken(userData.email)
                  .then(payload2 => {
                    useJwt.setUserData(payload2.data.userData)
                    useJwt.setToken(payload2.data.accessToken)
                    updateAbility(this.$ability)
                    this.$emit('atualizacaoGrid')
                  })
              })
              .catch(err => {
                this.$erro(err.response.error || 'Não foi possível deletar as empresas')
              })
              .finally(() => {
                this.close()
              })
          } else {
            this.close()
          }
        })
    },
    removerArquivo() {
      this.form.arquivoEmpresas = {
        url: null,
        fileName: null,
      }
    },
    show() {
      this.shown = true
      this.$bvModal.show('m-Importar')
    },
    close() {
      this.isLoading = false
      this.shown = false
      this.$bvModal.hide('m-Importar')
    },
  },
}
</script>
