/* eslint-disable import/no-cycle */
// import useJwt from '@core/auth/jwt/useJwt'
import JwtService from '@core/auth/jwt/jwtService'
import axios from '@axios'
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'

const jwt = new JwtService(axios, jwtDefaultConfig, router)

jwt.setUserData = userData => {
  localStorage.setItem('userData', JSON.stringify(userData))
}

export default jwt
